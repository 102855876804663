import { takeEvery, takeLatest, call, select, put } from 'redux-saga/effects';
import uuid from 'uuid';

import {
  getItems,
  updateItems,
  setItems,
  setLoadingFlag as setTableLoadingFlag,
} from 'components/InfiniteTableSelfStandingV3/actions';
import { selectItems } from 'components/InfiniteTableSelfStandingV3/selectors';
import config from 'config/config';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import { documentsApi } from 'containers/OneProjectPage/DocumentsPage/documentsApi';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { uploadMedia } from 'utils/chunkUploader';
import { documentType, apiFetchItemsCount } from 'utils/constants';
import request from 'utils/request';

import { setLoadingFlag } from './actions';
import { filterFailedUpload, filterSuccessfulUpload } from './components/utils/document';
import {
  ADD_DOCUMENTS,
  ADD_FOLDER,
  UPDATE_DOCUMENT,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILED,
} from './constants';

export function* addDocuments({ documents, listRequestOption, folderId }) {
  yield put(setLoadingFlag(true));
  const { namespace } = listRequestOption;
  const tableList = yield select(selectItems(namespace));
  const prevList = JSON.parse(JSON.stringify(tableList));
  const smallDocuments = documents.map(({ resultedBlob, ...smallDocument }) => smallDocument);

  yield put(setItems([...prevList, ...smallDocuments], namespace));
  for (let i = 0; i < documents.length; i += 1) {
    const item = documents[i];
    yield call(uploadDocument, {
      item,
      folderId,
      *callbackSuccess(doc) {
        const { resultedBlob, ...smallDoc } = doc;
        yield call(uploadDocumentSuccess, {
          item: {
            ...smallDoc,
            loading: false,
            failed: false,
          },
        });
      },
      *callbackError(doc) {
        const { resultedBlob, ...smallDoc } = doc;
        yield call(uploadDocumentFailed, {
          item: {
            ...smallDoc,
            loading: false,
            failed: true,
          },
        });
      },
    });
    yield put(documentsApi.util.invalidateTags(['Document']));
  }
}

export function* addFolder({ folder, listRequestOption, callback }) {
  yield put(setLoadingFlag(true));

  const { namespace, ...requestOption } = listRequestOption;

  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/documents`;

  const body = {
    id: uuid.v4(),
    type: documentType.folder,
    name: folder.name,
    ...(route.itemId && { parent_id: route.itemId }),
  };

  const options = {
    method: 'POST',
    headers: {
      'Cache-Control': 'No-Store',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const data = yield call(request, requestURL, options);
  if (data && !data.message) {
    yield put(
      getItems(
        {
          ...requestOption,
          urlParams: {
            ...requestOption?.urlParams,
            offset: 0,
            limit: apiFetchItemsCount,
          },
        },
        namespace,
      ),
    );
    Analytics.track('documents_folder_created');
    if (callback) {
      yield call(callback, data);
    }
    yield put(documentsApi.util.invalidateTags(['Document']));
  }
  yield put(setLoadingFlag(false));
}

export function* updateDocument({ document, listRequestOption, callback }) {
  yield put(setTableLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;
  const dataUpload = yield call(uploadMedia, document.media_resource, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });
  const payload = {
    name: document.name,
    media_resource: {
      root_media_resource_id: dataUpload.id,
      media_resource_id: dataUpload.id,
      media_resource_label: dataUpload.file_name,
    },
  };

  if (route?.itemId) payload.parent_id = route.itemId;
  if (document.parent_id) payload.parent_id = document.parent_id;
  if (document?.client_created_at) payload.client_created_at = document.client_created_at;
  if (document?.client_updated_at) payload.client_updated_at = document.client_updated_at;

  if (payload.parent_id === 'root') {
    payload.parent_id = null;
  }

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/documents/${document.id}`;

  const optionsUpload = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, optionsUpload);
  if (data && data?.message) {
    yield put(setTableLoadingFlag(false));
  } else if (listRequestOption) {
    const { namespace, ...requestOption } = listRequestOption;
    yield put(updateItems(requestOption, namespace));
  }
  if (callback) {
    yield call(callback);
  }
  yield put(documentsApi.util.invalidateTags(['Document']));
}

export function* renameDocument({ document, listRequestOption }) {
  yield put(setTableLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestURL = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/documents/${document.id}`;

  const payload = { name: document.name };
  if (route?.itemId) payload.parent_id = route.itemId;

  const optionsUpload = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  const data = yield call(request, requestURL, optionsUpload);

  if (data && data?.message) {
    yield put(setGlobalError(data));
    yield put(setTableLoadingFlag(false));
  } else {
    const { namespace, ...requestOption } = listRequestOption;
    yield put(updateItems(requestOption, namespace));
    yield put(setTableLoadingFlag(false));
  }
}

export function* uploadDocument({ item, folderId, callbackSuccess, callbackError }) {
  yield put(setLoadingFlag(true));

  const route = yield select(selectRouteParams());

  const requestUploadMediaUrl = `${checkOrSetSlash(
    config.apiHostGateway,
    'apiHostGateway',
  )}api/v1.2/projects/${route.idData}/`;
  const dataUpload = yield call(uploadMedia, item, {
    baseUrl: requestUploadMediaUrl,
    parallel: false,
    requestHeaders: {
      'Content-Type': 'application/json',
    },
  });

  if (dataUpload && !dataUpload.message) {
    const payload = {
      id: item.id,
      type: documentType.document,
      name: item.name,
      media_resource: {
        root_media_resource_id: dataUpload.id,
        media_resource_id: dataUpload.id,
        media_resource_label: dataUpload.file_name,
      },
    };

    if (folderId) {
      payload.parent_id = folderId;
    }

    const requestURL = `${checkOrSetSlash(
      config.apiHostGateway,
      'apiHostGateway',
    )}api/v1.2/projects/${route.idData}/documents`;

    const optionsUpload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    const data = yield call(request, requestURL, optionsUpload);
    if (data && (data.message || data.status === 500)) {
      yield call(callbackError, item);
    } else {
      Analytics.track('documents_file_created', {
        file_format: data.media_resource.media_resource_mime_type,
        size: data.media_resource.media_resource_size,
        source: 'documents',
      });

      yield call(callbackSuccess, {
        ...item,
        ...data,
        version: data.revision,
        type: documentType.document,
      });
    }
  }
  yield put(setLoadingFlag(false));
}

export function* uploadDocumentSuccess({ item, namespace = 'table' }) {
  yield put(setLoadingFlag(true));
  const tableList = yield select(selectItems(namespace));
  yield put(setItems(filterSuccessfulUpload(tableList, item), namespace));
}
export function* uploadDocumentFailed({ item, namespace = 'table' }) {
  yield put(setLoadingFlag(true));
  const tableList = yield select(selectItems(item));
  yield put(setItems(filterFailedUpload(tableList, item), namespace));
}
export default function* documentsPageSaga() {
  yield takeEvery(ADD_DOCUMENTS, addDocuments);
  yield takeEvery(ADD_FOLDER, addFolder);
  yield takeLatest(UPDATE_DOCUMENT, updateDocument);
  yield takeEvery(UPLOAD_DOCUMENT, uploadDocument);
  yield takeEvery(UPLOAD_DOCUMENT_SUCCESS, uploadDocumentSuccess);
  yield takeEvery(UPLOAD_DOCUMENT_FAILED, uploadDocumentFailed);
}
