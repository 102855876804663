import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { prepareFCHeader } from 'utils/request';

const rootProjectPath = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/`;

export const organizationMembersApi = createApi({
  reducerPath: 'organizationMembersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: rootProjectPath,
    prepareHeaders: prepareFCHeader(),
  }),
  tagTypes: ['OrganizationMembers'],
  endpoints: builder => ({
    getOrganizationMembers: builder.query({
      query: ({ baseApiRoute, searchTerm, limit = 100, filters, offset = 0 }) => ({
        url: `${baseApiRoute}?limit=${limit}&offset=${offset}`,
        method: 'POST',
        body: {
          search_term: searchTerm,
          roles: [...(filters?.roles || [])],
        },
      }),
      providesTags: ['OrganizationMembers'],
      keepUnusedDataFor: 0,
    }),
    getOrganizationRoles: builder.query({
      query: ({ baseApiRoute }) => ({
        url: `${baseApiRoute}`,
        method: 'GET',
      }),
      providesTags: ['OrganizationRoles'],
      keepUnusedDataFor: 0,
    }),
    createOrganizationMembers: builder.mutation({
      query: ({ baseApiRoute, roleUsers }) => {
        const body = {
          role_users: roleUsers?.map(user => ({
            role_id: user.organization_role_id,
            ...user,
          })),
        };
        return {
          url: baseApiRoute,
          method: 'POST',
          headers: {
            'Cache-Control': 'No-Store',
            'Content-Type': 'application/json',
          },
          body,
        };
      },
      invalidatesTags: ['OrganizationMembers'],
    }),
    removeOrganizationMembers: builder.mutation({
      query: ({ ids, baseApiRoute }) => ({
        url: `${baseApiRoute}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Cache-Control': 'No-Store' },
        body: JSON.stringify({
          user_ids: ids,
        }),
      }),
      invalidatesTags: ['OrganizationMembers'],
    }),
    updateOrganizationMembersRoles: builder.mutation({
      query: ({ roleId, userIds, baseApiRoute }) => ({
        url: `${baseApiRoute}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          organization_role_id: roleId,
          user_ids: userIds,
        }),
      }),
      invalidatesTags: ['OrganizationMembers', 'OrganizationRoles'],
    }),
  }),
});

export const {
  useLazyGetOrganizationMembersQuery,
  useLazyGetOrganizationRolesQuery,
  useCreateOrganizationMembersMutation,
  useRemoveOrganizationMembersMutation,
  useUpdateOrganizationMembersRolesMutation,
} = organizationMembersApi;
