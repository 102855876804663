import React from 'react';

import { styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import TextCellSkeleton from 'components/SkeletonScreen/components/TextCellSkeleton';
import { TypographyEllipsis } from 'components/Templates/TypographyEllipsis';
import { Tile } from 'components/Tile/Tile';

// #region [styled]
const Div_Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Div_BottomLineWrapper = styled('div')(
  ({ theme, hasTwoElements }) =>
    hasTwoElements
      ? {
          marginTop: theme.spacing(0.5),
          lineHeight: '0.875rem',
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
        }
      : { marginTop: theme.spacing(0.5), lineHeight: '0.875rem' },
);
// #endregion

const TextCell = ({
  icon,
  mainText,
  mainTextVariant = 'subtitle',
  description,
  loading,
  tile,
  tileColor,
  tooltipTitle,
}) =>
  // #region [render]
  loading ? (
    <TextCellSkeleton hasDescription={!!description} />
  ) : (
    <Div_Container>
      {icon && mainText && icon}
      <div>
        {(() => {
          const mainTextTypo = (
            <TypographyEllipsis noWrap component="p" variant={mainTextVariant}>
              {mainText || '-'}
            </TypographyEllipsis>
          );
          return tooltipTitle ? (
            <Tooltip
              title={tooltipTitle}
              placement="bottom"
              leaveDelay={10}
              key={`tooltip-textCell-${mainText}`}
            >
              {mainTextTypo}
            </Tooltip>
          ) : (
            mainTextTypo
          );
        })()}

        {(description || tile) && (
          <Div_BottomLineWrapper hasTwoElements={description && tile}>
            {description && (
              <TypographyEllipsis noWrap component="span" variant="subtitleLight">
                {description}
              </TypographyEllipsis>
            )}
            {tile && <Tile color={tileColor} label={tile} />}
          </Div_BottomLineWrapper>
        )}
      </div>
    </Div_Container>
  );
// #endregion

TextCell.propTypes = {
  mainText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mainTextVariant: PropTypes.oneOf(['subtitle', 'subtitleLight', 'subtitleBold']),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  loading: PropTypes.bool,
  tile: PropTypes.string,
  tileColor: PropTypes.oneOf(['secondary', 'info', 'success', 'warning']),
  tooltipTitle: PropTypes.string,
};

export default TextCell;
