import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import uuid from 'uuid';

import config from 'config/config';
import Analytics from 'utils/Analytics';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import { locationType } from 'utils/constants';
import { prepareFCHeader } from 'utils/request';

const rootProjectPath = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/`;

const createMediaResourceFromDocument = document => {
  const { id, mime_type, name, md5, size, width, height, data, url } = document.media_resource;

  return {
    media_resource_id: id,
    media_resource_mime_type: mime_type,
    media_resource_display_name: name,
    media_resource_md5: md5,
    media_resource_size: size,
    media_resource_width: width,
    media_resource_height: height,
    media_resource_data: data,
    url,
  };
};

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: rootProjectPath,
    prepareHeaders: prepareFCHeader(),
  }),
  tagTypes: ['Document'],
  endpoints: builder => ({
    getDocuments: builder.query({
      query: ({ projectId, parent_id, search_term, filters, limit, offset }) => ({
        url: `projects/${projectId}/documents/filter?limit=${limit}&offset=${offset}`,
        method: 'POST',
        body: {
          ...filters,
          assignee_ids: [
            ...(filters?.assignee_ids ? filters?.assignee_ids : []),
            ...(filters?.assigned_to_me ? filters?.assigned_to_me : []),
          ],
          parent_id,
          search_term,
        },
      }),
      providesTags: ['Document'],
    }),
    fetchFoldersContent: builder.query({
      query: ({ projectId, id }) => {
        const folderQuery = id ? `document_id=${id}&` : '';
        return {
          url: `projects/${projectId}/documents/children-folders?${folderQuery}offset=0&limit=500`,
          method: 'GET',
          headers: { 'Cache-Control': 'No-Store' },
        };
      },
      transformResponse: (response, meta, arg) =>
        arg.id
          ? response.documents
          : response.documents
              .filter(element => element.parent_id === null)
              .sort((a, b) => a.name.localeCompare(b.name)),
      providesTags: ['Document'],
    }),
    fetchPlanFoldersContent: builder.query({
      query: ({ projectId, id }) => {
        const folderQuery = id ? `/${id}/children-folders?` : '?';
        return {
          url: `projects/${projectId}/folders${folderQuery}offset=0&limit=500`,
          method: 'GET',
          headers: { 'Cache-Control': 'No-Store' },
        };
      },
      transformResponse: (response, meta, arg) =>
        arg.id
          ? response.folders
          : response.folders
              .filter(element => !element.parent_folder_id || element.parent_folder_id === null)
              .sort((a, b) => a.name.localeCompare(b.name)),
      invalidatesTags: ['Document'],
    }),
    createAsPlan: builder.mutation({
      query: ({ projectId, folderId, document }) => {
        const requestURL = `${checkOrSetSlash(
          config.apiHostGateway,
          'apiHostGateway',
        )}api/v1.3/projects/${projectId}/plans`;

        const payload = {
          plan_id: uuid.v4(),
          folder_id: folderId !== 'root' ? folderId : '',
          plan_type: locationType.plan,
          name: document.name,
          document_id: document.id,
          media_resource_from_document: createMediaResourceFromDocument(document),
          media_resource: {
            root_media_resource_id: document.media_resource.id,
            media_resource_id: document.media_resource.id,
            media_resource_label: document.name,
          },
        };

        return {
          url: requestURL,
          method: 'POST',
          headers: {
            'Cache-Control': 'No-Store',
            'Content-Type': 'application/json',
          },
          body: payload,
        };
      },
      invalidatesTags: ['Document'],
    }),
    deleteDocument: builder.mutation({
      query: ({ projectId, id }) => ({
        url: `projects/${projectId}/documents/${id}`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Document'],
    }),
    renameDocument: builder.mutation({
      query: ({ projectId, id, name, parentId }) => ({
        url: `projects/${projectId}/documents/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: { name, parent_id: parentId },
      }),
      invalidatesTags: ['Document'],
    }),
    moveItemsTo: builder.mutation({
      query: ({ projectId, parentId, items }) => {
        const requestURL = `${checkOrSetSlash(
          config.apiHostGateway,
          'apiHostGateway',
        )}api/projects/${projectId}/documents/change-parent-folder`;

        const payload = {
          parent_id: parentId !== 'root' ? parentId : null,
          moved_document_ids: items.map(i => i.id),
        };

        return {
          url: requestURL,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: payload,
        };
      },
      transformResponse: (response, meta, arg) => {
        if (response[0].parent_id) {
          Analytics.track('documents_folder_moved');
        } else {
          Analytics.track('documents_file_moved');
        }
        return response;
      },
      invalidatesTags: ['Document'],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useFetchFoldersContentQuery,
  useLazyFetchFoldersContentQuery,
  useLazyFetchPlanFoldersContentQuery,
  useCreateAsPlanMutation,
  useDeleteDocumentMutation,
  useRenameDocumentMutation,
  useMoveItemsToMutation,
} = documentsApi;
